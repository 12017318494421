<script setup lang="ts">
    import ContactUsComponent from '@/components/ContactUsComponent.vue';
    import IntroComponent from '@/components/IntroComponent.vue';
    import ProjectsComponent from '@/components/ProjectsComponent.vue';
    import { ref } from 'vue';

    const skillsRef = ref();
    
</script>

<template>
    <div>
        <IntroComponent @scrollToSkills="skillsRef.$el.scrollIntoView({ behavior: 'smooth' })"/>
        <ProjectsComponent ref="skillsRef"/>
        <ContactUsComponent/>
    </div>
</template>
 
<style scoped>

</style>
