<template>
    <div class="flex-row">
        <h3>C&C Ventures</h3>
    </div>
</template>

<script setup lang="ts">
    
</script>

<style scoped>
    div {
        height: 5vh;
    }

    h3 {
        margin-left: 15px;
        font-weight: lighter;
        color: #64FFDA;
    }

    img {
        max-height: 5rem;
    }
</style>