<template>
    <div class="component c-bl2">

        <h2 class="c-gy2 pb1">Products</h2>

        <div class="card-grid">
            <div class="card mr1">
                <div>
                    <h3 class="c-gr">NFL Statistics University</h3>
                    <p class="c-gr">Website</p>
                    <p class="c-gy1">Here we do statistical analysis on NFL games. View fun stats or even get a let up in fantasy football</p>
                    <button @click="openNewTab('nflstatuni.com')">View</button>
                </div>
            </div>
            <div class="card ml1">
                <div>
                    <h3 class="c-gr">Speed Grocer</h3>
                    <p class="c-gr">Mobile App - iOS & Android</p>
                    <p class="c-gy1">Grocery App that solves all your grocery problems! Learn more by checking it the website</p>
                    <button @click="openNewTab('speedgrocer.io')">View</button>
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script setup lang="ts">
    
    function openNewTab(url: string) {
      window.open('http://'+url, '_blank');
    }
    
  </script>
  
  <style scoped>
  .card-grid {
      display: flex;
      flex-wrap: wrap;
  }
  
  .card {
    padding: 1rem;
      flex: 1 0 30%;
      background-color: var(--darkblue);
      box-shadow: 1px 15px 30px -15px black;
      font-size: 15px;
      line-height: 1.5;
      z-index: 2;
      display: flex;
  }
  
  @media (max-width: 600px) {
      .card {
          flex: 1 0 100%;
      }
  }
  </style>